import { StandardRes } from "@/models/response";
import { JoystickUpdateRequest } from "@/models/joystickUpdateRequest";
import { JobParameters, UpdateConfigParams, TelegramChatMessage } from "@/models/models";

// export const getConfigVersionsDtoPage = async (productContentId: string, envContentId: string, contentId: string, page: number, pageSize: number): Promise<DataRes<ConfigListPageDto>> => {
//   const configs = await request<ConfigListPageDto>(`/product/${productContentId}/env/${envContentId}/config/${contentId}/pageDto?page=${page}&pageSize=${pageSize}`);
//   configs.data.page = configs.data.page.map((x: ConfigDto) => new ConfigDto(x)).sort((x, y) => y.version - x.version);
//   return configs;
// }

const baseUrl = process.env.VUE_APP_API_BASE_URL ?? "/api";

export const getHello = async (): Promise<StandardRes> => {
  const url = `${baseUrl}/hello`;

  const response = await fetch(url);
  const data = await response.json();
  return data;
};

export const chatWithMe = async (promptText: string): Promise<StandardRes> => {
  const url = `${baseUrl}/chat`;

  console.log("I'm about to send: ", promptText);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ promptText }),
  });

  const promptRes: StandardRes = await response.json();
  return promptRes;
};

export const drawWithMe = async (promptText: string): Promise<StandardRes> => {
  return sendDrawRequest(promptText, "dalle");
};

export const drawWithMeStability = async (promptText: string): Promise<StandardRes> => {
  return sendDrawRequest(promptText, "stability");
};

// take the duplicated code in drawWithMe and drawWithMeStability and put it in a function
const sendDrawRequest = async (promptText: string, model: string): Promise<StandardRes> => {
  const url = `${baseUrl}/draw/${model}`;
  let promptRes: StandardRes = { status: 0, statusText: "", data: {} };

  if (!promptText) {
    console.log("Prompt cannot be blank.");
    return promptRes;
  }

  console.log(`I'm about to draw using ${model}: `, promptText);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ promptText }),
  });
  promptRes = await response.json();

  return promptRes;
};

/**
 * Update a Joystick config
 * @param configContentId the content id of the config to update
 * @param updateBody the body of the update request. Must be a well-formed JoystickUpdateRequest.
 * @returns the response from the joystick api as a StandardRes.
 */
export const updateJoystickConfig = async (configContentId: string, updateBody: JoystickUpdateRequest): Promise<StandardRes> => {
  const url = `${baseUrl}/update-joystick-config/${configContentId}`;

  console.log("I'm about to update: ", configContentId);

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateBody),
  });

  const updateRes: StandardRes = await response.json();
  return updateRes;
};

export const buildJobDescription = async (jobParameters: JobParameters): Promise<StandardRes> => {
  const url = `${baseUrl}/job/build-job-description`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jobParameters),
  });

  const jobDescription: StandardRes = await response.json();
  return jobDescription;
};

export const getJobCategories = async (): Promise<StandardRes> => {
  const url = `${baseUrl}/job/categories`;

  // TODO: Improve ApiService and make a generic request/response method.
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json"
    }
  });

  const data = await response.json();
  return data as StandardRes;
};

export const getPricingAllTrips = async (inputFactors: any): Promise<StandardRes> => {
  const url = `${baseUrl}/pricing/all-trips`;
  console.log("I'm about to send: ", inputFactors);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(inputFactors)
  });

  const data = await response.json();
  return data as StandardRes;
};

export const createStoryboards = async (inputFactors: any): Promise<StandardRes> => {
  const url = `${baseUrl}/story/generate-storyboards`;
  console.log("I'm about to send: ", inputFactors);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(inputFactors)
  });

  const data = await response.json();
  return data as StandardRes;
};

// config passthrough
export const getStoryConfigs = async (): Promise<StandardRes> => {
  const url = `${baseUrl}/story/configs`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  const data = await response.json();
  return data as StandardRes;
};

export const updateConfigOffer = async (updateConfigParams: UpdateConfigParams): Promise<StandardRes> => {
  const url = `${baseUrl}/config/update/simple_offer`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateConfigParams),
  });

  const updateConfigRes: StandardRes = await response.json();
  return updateConfigRes;
};

export const updateConfigGalleryData = async (updateBody: JoystickUpdateRequest): Promise<StandardRes> => {
  const url = `${baseUrl}/config/update/gallery_data`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateBody),
  });

  const updateConfigRes: StandardRes = await response.json();
  return updateConfigRes;
};

export const updateConfigArtGallery = async (updateConfigParams: UpdateConfigParams): Promise<StandardRes> => {
  const url = `${baseUrl}/config/update/environment_custom_data`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateConfigParams),
  });

  const updateConfigRes: StandardRes = await response.json();
  return updateConfigRes;
};

class ApiService {
  // call the api and return the response
  public async callApi(endpoint: string, options: any = {}) {
    const url = baseUrl + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
  }
}

export default new ApiService();

export const sendTelegramMessage = async(params: TelegramChatMessage): Promise<StandardRes> => {
  const url = `${baseUrl}/metropoly/send/${params.chatId}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
  return await response.json();
};

export const sendTelegramLink = async(params: TelegramChatMessage): Promise<StandardRes> => {
  const url = `${baseUrl}/metropoly/sendLink/${params.chatId}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    }
  });
  return await response.json();
};
