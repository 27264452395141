import { createClient } from "@supabase/supabase-js";
import { store } from "@/store";

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL ?? "";
const supabaseAnonKey = process.env.VUE_APP_SUPABASE_ANON_KEY ?? "";

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// setup auth state listener
supabase.auth.onAuthStateChange((event, session) => {
  // the "event" is a string indicating what trigger the state change (ie. SIGN_IN, SIGN_OUT, etc)
  // the session contains info about the current session most importantly the user data
  // const { user } = authUser();

  // if the user exists in the session we're logged in
  // and we can set our user reactive ref
  store.state.user = session?.user || null;
  console.log("Auth State Changed!", event, session);
});

export default supabase;
