import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createElementVNode("div", null, [
            _createTextVNode(" Powered by "),
            _createElementVNode("img", {
              class: "logo",
              src: require('@/assets/joystick-logo-500.png'),
              alt: "Joystick Logo",
              width: "150"
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => [
                _createTextVNode("Home")
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/about" }, {
              default: _withCtx(() => [
                _createTextVNode("About")
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/job-builder" }, {
              default: _withCtx(() => [
                _createTextVNode("Job Builder")
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/dynamic-pricing" }, {
              default: _withCtx(() => [
                _createTextVNode("Dynamic Pricing")
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/stories" }, {
              default: _withCtx(() => [
                _createTextVNode("Story Book")
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/farm" }, {
              default: _withCtx(() => [
                _createTextVNode("Dynamic Farm")
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/gallery-director" }, {
              default: _withCtx(() => [
                _createTextVNode("Gallery Manager")
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/update" }, {
              default: _withCtx(() => [
                _createTextVNode("Natural Language")
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/tg" }, {
              default: _withCtx(() => [
                _createTextVNode("TG Chat")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_router_link, { to: "/profile" }, {
              default: _withCtx(() => [
                _createTextVNode("Profile")
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}