
import { Vue } from "vue-class-component";
import supabase from "../supabase";

// @Options({

// })

export default class AuthView extends Vue {
  public loading = false;
  public email = "";

  getUrl = (): string => {
    let url =
      process?.env?.VUE_APP_SITE_URL ?? // Set this to your site URL in production env.
      "http://localhost:8080/";
    // Make sure to include `https://` when not localhost.
    url = url.includes("http") ? url : `https://${url}`;
    // Make sure to including trailing `/`.
    url = url.charAt(url.length - 1) === "/" ? url : `${url}/`;
    return url;
  };

  async handleLogin() {
    // NOTE: when a new user is created, there is a trigger that automatically creates an entry in the profiles table.
    // When creating a new supabase project, you have to run the User Management Starter SQL.

    console.log("Redirect Url: ", this.getUrl());
    try {
      this.loading = true;
      const { error } = await supabase.auth.signInWithOtp({
        email: this.email,
        options: {
          emailRedirectTo: this.getUrl()
        }
      });
      if (error) throw error;
      alert("Check your email for the login link!");
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    } finally {
      this.loading = false;
    }
  }
}
