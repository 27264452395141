
// import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import { Vue } from "vue-class-component";
import { getHello, chatWithMe, drawWithMe, updateJoystickConfig } from "@/services/apiService";
import { CreateImageApiResponse } from "@/models/models";

// @Options({
//   components: {
//     HelloWorld
//   }
// })

export default class HomeView extends Vue {
  public promptText = "";
  public chatResponseText = "";
  public drawResponse: CreateImageApiResponse | null = null;
  public updateJoystickConfigStatus = "";

  async mounted() {
    const myVar = await getHello();
    console.log(myVar);
  }

  async chatWithMe() {
    this.drawResponse = null;
    this.chatResponseText = "";

    const chatPromptRes = await chatWithMe(this.promptText);
    console.log("chatPromptRes: ", chatPromptRes);
    this.chatResponseText = chatPromptRes.data.response.content;
  }

  async drawWithMe() {
    this.drawResponse = null;
    this.chatResponseText = "";

    const drawPromptRes = await drawWithMe(this.promptText);
    console.log("drawPromptRes: ", drawPromptRes);
    this.drawResponse = drawPromptRes.data.response;
  }

  async updateConfig() {
    this.updateJoystickConfigStatus = "Updating to Joystick...";

    const configContentId = "config01";
    const updateData = {
      d: "From Gallery Director Testing",
      c: {
        hello: "Great day to you!",
        dynamicString: "#LK-String:string:hello#",
        dynamicNumber: "#LK-Number:number:123#",
        dynamicBoolean: "#LK-Boolean:boolean:true#",
      },
      m: [],
    };

    const updateJoystickRes = await updateJoystickConfig(configContentId, updateData);

    if (updateJoystickRes.status !== 1) {
      this.updateJoystickConfigStatus = "😞 Failed to update joystick config.";
    } else {
      this.updateJoystickConfigStatus = "😎 Successfully updated joystick config.";
    }
  }
}
