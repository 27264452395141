
import { Vue } from "vue-class-component";
import supabase from "../supabase";
import { store } from "../store";
import router from "../router";

export default class ProfileView extends Vue {
  public loading = false;
  public username = "";
  public website = "";
  public avatarUrl = "";
  public showForm = false;
  public userEmail = store.state.user?.email;

  async mounted() {
    console.log("ProfileView mounted.");
    await this.getProfile();
  }

  async getProfile() {
    console.log("Profile: Logged In: ", store.isLoggedIn());
    console.log("Profile: Logged In User: ", store.state.user);

    if (store.isLoggedIn()) {
      try {
        this.loading = true;
        const user = store.state.user;

        // Get from supabase
        const { data, error, status } = await supabase
          .from("profiles")
          .select(`username, website, avatar_url`)
          .eq("id", user?.id)
          .single();

        if (error && status !== 406) throw error;

        if (data) {
          this.username = data.username;
          this.website = data.website;
          this.avatarUrl = data.avatar_url;
          this.showForm = true;
        }
      } catch (error) {
        if (error instanceof Error) {
          console.log("Could not access DB: ", error.message);
        } else {
          console.log("Could not access DB: ", error);
        }
      } finally {
        this.loading = false;
      }
    }
  }

  async updateProfile() {
    try {
      this.loading = true;

      // const user = store.state.user;
      const { data: { user } } = await supabase.auth.getUser();

      const updates = {
        id: user?.id, // Shouldn't be null if the logged in check passed
        username: this.username,
        website: this.website,
        avatar_url: this.avatarUrl,
        updated_at: new Date(),
      };

      const { error } = await supabase.from("profiles").upsert(updates);

      if (error) throw error;
    } catch (error) {
      if (error instanceof Error) {
        console.log("Could Not Update Profile: ", error.message);
        alert(`Could not update profile: ${error.message}`);
      } else {
        alert(`Could not update profile: ${error}`);
        alert(error);
      }
    } finally {
      this.loading = false;
    }
  }

  async signOut() {
    try {
      this.loading = true;
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      store.state.user = null;
      router.push({ name: "auth" });
    } catch (error) {
      if (error instanceof Error) {
        console.log("Could Not Sign Out: ", error.message);
        alert(error.message);
      } else {
        console.log("Could Not Sign Out: ", error);
        alert(error);
      }
    } finally {
      this.loading = false;
    }
  }
}

