
import { store } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  computed: {
    isLoggedIn() {
      return store.isLoggedIn();
    },
  },
})

export default class AppView extends Vue {
  public isLoggedIn!: boolean;
}
