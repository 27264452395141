import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AuthView from "../views/Auth.vue";
import ProfileView from "../views/Profile.vue";
import supabase from "../supabase";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/auth",
    name: "auth",
    component: AuthView
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
  },
  {
    path: "/gallery-director",
    name: "gallery-director",
    component: () => import(/* webpackChunkName: "gallery-director" */ "../views/GalleryDirector.vue")
  },
  {
    path: "/job-builder",
    name: "job-builder",
    component: () => import(/* webpackChunkName: "gallery-director" */ "../views/JobBuilder.vue")
  },
  {
    path: "/dynamic-pricing",
    name: "dynamic-pricing",
    component: () => import(/* webpackChunkName: "gallery-director" */ "../views/DynamicPricing.vue")
  },
  {
    path: "/stories",
    name: "stories",
    component: () => import(/* webpackChunkName: "stories" */ "../views/Stories.vue")
  },
  {
    path: "/story/:uuid",
    name: "story",
    component: () => import(/* webpackChunkName: "story" */ "../views/Story.vue")
  },
  {
    path: "/farm",
    name: "farm",
    component: () => import(/* webpackChunkName: "farm" */ "../views/FarmControl.vue")
  },
  {
    path: "/update",
    name: "update",
    component: () => import(/* webpackChunkName: "farm" */ "../views/ConfigUpdater.vue")
  },
  {
    path: "/tg",
    name: "tg",
    component: () => import(/* webpackChunkName: "farm" */ "../views/TgChat.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach(async (to, from, next) => {
  try {
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;

    console.log("Router: session data: ", data);
    console.log("Attempting to go to: ", to.name);

    // User is not logged in. Redirect to Auth.
    if ((typeof data === "undefined" || !data?.session) && to.name !== "auth") {
      console.log("You are not logged in. Redirecting to Auth page.");
      next({ name: "auth" });
    } else if (data?.session && to.name === "auth") {
      // User is logged in, trying to get to auth page -> redirect to homepage.
      console.log("You are logged in. Redirecting to Home.");
      next({ name: "home" });
    } else {
      console.log("Proceeding to: ", to.name);
      next();
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log("Error getting session: ", error.message);
      alert(`Error With Login:  ${error.message}`);
    } else {
      console.log("Error getting session: ", error);
      alert(`Error With Login. Cannot Proceed.`);
    }
    // Error, redirect to auth page.
    console.log("Not Logged In. Redirecting to Auth.");
    next({ name: "auth" });
  }
});

export default router;
