import { reactive } from "vue";
import { User } from "@supabase/supabase-js";

export const store = {
  state: reactive({
    user: null as User | null
  }),
  isLoggedIn() {
    return this.state.user !== null;
  }
};
